import { AudioTrack, Content, MediaFeedType } from '@mlbtv-clients/services';
import { AdobeTracker, actionEventIds } from 'analytics/adobe';
import BroadcastOptionFeedLock from 'assets/feedSelect/feedLock.svg?react';
import { buildGameVideoToObject } from 'components/FeedSelect/utils';
import { TeamLogo } from 'components/TeamLogo';
import { formatAudioFeedText } from 'components/video/GameMenu/GameMenuOptions/utils';
import { AUDIO_FEED, HOME_FEED_TYPE, VIDEO_FEED } from 'constants/feeds';
import { gameMenuIds, videoInfoModalIds } from 'constants/testIds';
import { usePlayerContext } from 'context/PlayerContext';
import { ContentField } from 'experience/MLB/constants';
import { getContentFieldValue } from 'experience/MLB/utils';
import { useBookmarks } from 'hooks/player';
import { useAdobeGameMenuParams } from 'hooks/player/useAdobeGameMenuParams/useAdobeGameMenuParams';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { setOpenModal } from 'store/app';
import { selectGameTileTeamColors, selectTeamNameById } from 'store/branding';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { StartAt, resetPlayer, setPrevFocusedFeed } from 'store/player';
import { selectEpgFeedByMediaId, selectGameTeamIds, setSelectedFeed } from 'store/selectedVideo';

import { useTextToSpeech } from 'hooks';
import { BroadcastOptionNetworkFeedLogo } from './BroadcastOptionNetworkFeedLogo';
import {
  BroadcastOptionsFeedButton,
  BroadcastOptionsFeedButtonContent,
  BroadcastOptionsFeedTeamLogo,
  BroadcastOptionsFeedText,
  BroadcastOptionsLogoAndText,
} from './BroadcastOptionsFeed.styles';

interface BroadcastOptionsFeedProps {
  audioTrack?: AudioTrack;
  feed: Content;
  index: number;
}

const { BROADCAST_FEED_LOCK_ICON, BROADCAST_OPTIONS_FEED } = gameMenuIds;
const { MODAL } = videoInfoModalIds;
const { BROADCAST_OPTIONS_AUDIO_FEED_CLICK, BROADCAST_OPTIONS_VIDEO_FEED_CLICK } = actionEventIds();

export function BroadcastOptionsFeed({ audioTrack, feed, index }: BroadcastOptionsFeedProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const { getCurrentTime, hls } = usePlayerContext();
  const { t } = useTextToSpeech();
  const { awayTeamId, homeTeamId } = useAppSelector(selectGameTeamIds);
  const awayTeamName = useAppSelector((state) => selectTeamNameById(state, awayTeamId));
  const homeTeamName = useAppSelector((state) => selectTeamNameById(state, homeTeamId));

  const selectedVideoContentId = params.get('contentId') ?? '';
  const gamePk = params.get('gamePk') ?? '';

  const { date } = useDateFromURL();

  const teamBackgroundColors = useAppSelector(selectGameTileTeamColors);
  const { callSign, contentId, contentRestrictionDetails, feedType, mediaId } = feed;
  const epgFeed = useAppSelector((state) => selectEpgFeedByMediaId(state, mediaId));

  const feedText = audioTrack ? `Audio Feed #${index + 1}` : callSign;
  const adobeFeedParams = useAdobeGameMenuParams(feedText);

  const { updateBookmark } = useBookmarks(contentId);

  const buttonText = audioTrack ? formatAudioFeedText(audioTrack) : callSign;
  const entitled = contentRestrictionDetails.length === 0;
  const teamId = parseInt(
    (feedType === MediaFeedType.AWAY
      ? getContentFieldValue(feed, ContentField.AwayTeamId)
      : getContentFieldValue(feed, ContentField.HomeTeamId)) ?? '',
    10,
  );
  const isNetworkFeed = feedType === MediaFeedType.NETWORK;
  const selectedHlsTrack = hls?.audioTracks[hls.audioTrack];
  const isSelectedFeed = audioTrack
    ? selectedHlsTrack?.name === audioTrack.renditionName
    : selectedVideoContentId === feed.contentId;

  const focusKey = `${BROADCAST_OPTIONS_FEED}-${audioTrack ? AUDIO_FEED : VIDEO_FEED}-${index}`;
  const focusOptions = {
    focusKey,
    overrideDefaultFocusBehavior: true,
  };

  const trackBroadcastOptionsClick = () => {
    const action = audioTrack
      ? BROADCAST_OPTIONS_AUDIO_FEED_CLICK
      : BROADCAST_OPTIONS_VIDEO_FEED_CLICK;
    AdobeTracker.trackAction(action, adobeFeedParams);
  };

  const changeAudioFeed = () => {
    const matchedAudioTrackIndex =
      hls?.audioTracks.findIndex((track) => track.name === audioTrack?.renditionName) ?? 0;
    const newAudioTrackIndex = matchedAudioTrackIndex === -1 ? 0 : matchedAudioTrackIndex;

    if (hls) hls.audioTrack = newAudioTrackIndex;

    const currentTime = getCurrentTime();
    updateBookmark(currentTime);
  };

  const changeVideoFeed = () => {
    const currentTime = getCurrentTime();
    updateBookmark(currentTime, true);

    const to = buildGameVideoToObject({ contentId, date, gamePk, mediaId });
    const from = location.state?.from;

    navigate(to, { state: { from, startAt: StartAt.RESUME } });

    dispatch(resetPlayer());
    if (epgFeed) dispatch(setSelectedFeed(epgFeed));
  };

  const onPress = () => {
    if (isSelectedFeed) return;

    if (!entitled) {
      dispatch(setPrevFocusedFeed(focusKey));
      dispatch(setOpenModal(MODAL));
      return;
    }

    audioTrack ? changeAudioFeed() : changeVideoFeed();

    trackBroadcastOptionsClick();
  };

  const isSelectedTTS = isSelectedFeed
    ? t('gameMenu.broadcastOptions.selectedFeed')
    : t('gameMenu.broadcastOptions.unselectedFeed');

  return (
    <BroadcastOptionsFeedButton
      $isSelectedFeed={isSelectedFeed}
      data-testid={focusKey}
      aria-label={t(`gameMenu.broadcastOptions.feedOnFocus`, {
        feed: buttonText,
        isSelectedTTS,
        teamName: feedType === HOME_FEED_TYPE ? homeTeamName : awayTeamName,
        videoOrAudio: audioTrack
          ? t('gameMenu.broadcastOptions.audioFeed')
          : t('gameMenu.broadcastOptions.videoFeed'),
      })}
      focusOptions={focusOptions}
      onPress={onPress}>
      <BroadcastOptionsFeedButtonContent>
        <BroadcastOptionsLogoAndText>
          {isNetworkFeed ? (
            <BroadcastOptionNetworkFeedLogo
              callLetters={audioTrack ? audioTrack.name : callSign}
              isAudioFeed={!!audioTrack}
            />
          ) : (
            <BroadcastOptionsFeedTeamLogo $backgroundColor={teamBackgroundColors[teamId]}>
              <TeamLogo size={2.7} teamId={teamId} />
            </BroadcastOptionsFeedTeamLogo>
          )}
          <BroadcastOptionsFeedText>{buttonText}</BroadcastOptionsFeedText>
        </BroadcastOptionsLogoAndText>
        {!entitled && <BroadcastOptionFeedLock data-testid={BROADCAST_FEED_LOCK_ICON} />}
      </BroadcastOptionsFeedButtonContent>
    </BroadcastOptionsFeedButton>
  );
}
