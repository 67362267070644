/* eslint-disable camelcase */
import Conviva from '@convivainc/conviva-js-coresdk';
import { AdobeDpmResponse } from 'analytics/adobe';
import { getDeviceMetadata, getDeviceModelYear } from 'utils/platform';
import { HlsConfig } from 'hls.js';
import api, { GET } from 'services/api';
import { Config } from 'store/config';
import fallback from 'store/config/fallback.json';

const {
  Constants: { DeviceMetadata },
} = Conviva;

const { BRAND, MODEL, OS_NAME } = DeviceMetadata;

export interface AdobeTargetData {
  config?: HlsConfig;
  id: string;
}

export interface AdobeTargetContent {
  content?: AdobeTargetData[];
}

export type AdobeTargetResponse = AdobeTargetContent[] | undefined;

export const OFFER_HLSJS_CONFIG = 'mlbtv-app-configs-hlsjs-ab';

export const getAdobeIdentifier = async (config: Config) => {
  const {
    analytics: {
      omniture: {
        marketingCloud: { org },
      },
    },
  } = config;

  const dpmResponse = await api<AdobeDpmResponse>(GET, {
    endpoint: 'id',
    queryParams: { orgId: org },
    service: 'analytics',
  });
  const { d_mid } = dpmResponse.data;

  return d_mid;
};

export const getHlsjsConfigOffer = async (
  deviceMetadata: typeof fallback.conviva.deviceMetadata,
) => {
  const metadata = await getDeviceMetadata(deviceMetadata);
  const year = await getDeviceModelYear();
  const params: {
    deviceBrand?: string;
    modelName?: string;
    modelYear?: string;
    osVersion?: string;
  } = {};

  if (metadata[BRAND]) params.deviceBrand = metadata[BRAND];
  if (metadata[MODEL]) params.modelName = metadata[MODEL];
  if (year) params.modelYear = year;
  if (metadata[OS_NAME]) params.osVersion = metadata[OS_NAME];

  const hlsConfig = await new Promise<AdobeTargetData | undefined>((resolve) =>
    window.adobe.target.getOffer({
      error: () =>
        resolve({
          id: 'remote',
        }),
      mbox: OFFER_HLSJS_CONFIG,
      params,
      success: (data: AdobeTargetResponse) => resolve(data?.[0].content?.[0]),
    }),
  );

  return hlsConfig;
};
