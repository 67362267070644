import { Isu } from '@mlbtv-clients/isu';
import {
  AdExperienceType,
  Content,
  contentSearch,
  ContentType,
  Device,
  Experience,
  initPlaybackSession,
  initSession,
  InitSession,
  LanguagePreference,
  MediaInfo,
  PlaybackQuality,
  PlaybackSession,
  requestExperience,
  requestMediaInfo,
} from '@mlbtv-clients/services';
import { MLBExperienceConfig } from 'experience/MLB/config';
import { MLBInitPlaybackSessionArgs } from 'experience/MLB/types';
import { getClientType } from 'utils/platform';
import { getOktaAuthStorage } from 'utils/storage';

export const getDefaultDevice = (): Device => {
  const isu = Isu();
  const { browser, browserVersion, deviceFamily, model, platform, platformVersion, vendor } = isu;

  const device = {
    appVersion: __VERSION__,
    deviceFamily,
    extraAttrs: {
      browserName: browser,
      browserVersion,
    },
    languagePreference: LanguagePreference.ENGLISH,
    manufacturer: vendor,
    model,
    os: platform,
    osVersion: platformVersion,
  };

  return device;
};

export const mgwContentSearchByGamePk = async (
  gamePk: string,
  config: MLBExperienceConfig,
): Promise<Content[]> => {
  const { accessToken } = getOktaAuthStorage();
  const clientType = await getClientType();
  const response = await contentSearch({
    query: `GamePk = ${gamePk} AND ContentType = "${ContentType.GAME}" RETURNING AwayTeamId, ContentId, HomeTeamId, MediaId`,
    serviceConfig: config.contentSearch,
    ...(accessToken && {
      requestData: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-client-name': clientType,
          'x-client-version': __VERSION__,
        },
      },
    }),
  });

  return response;
};

export const mgwInitPlaybackSession = async ({
  config,
  device,
  mediaId,
  mlbSessionId,
}: MLBInitPlaybackSessionArgs): Promise<PlaybackSession> => {
  const { accessToken } = getOktaAuthStorage();
  const clientType = await getClientType();
  const playbackSession = await initPlaybackSession({
    adCapabilities: [AdExperienceType.GOOGLE_STANDALONE_AD_PODS],
    deviceId: device.knownDeviceId ?? '',
    mediaId,
    quality: PlaybackQuality.PLACEHOLDER,
    requestData: {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'x-client-name': clientType,
        'x-client-version': __VERSION__,
      },
    },
    serviceConfig: config.playbackSession,
    sessionId: mlbSessionId,
  });

  return playbackSession;
};

export const mgwRegisterDevice = async (
  config: MLBExperienceConfig,
  device: Device,
): Promise<InitSession> => {
  const { accessToken } = getOktaAuthStorage();
  const clientType = await getClientType();

  const response = await initSession({
    clientType,
    device,
    requestData: {
      headers: {
        ...(accessToken && {
          Authorization: `Bearer ${accessToken}`,
        }),
        'x-client-name': clientType,
        'x-client-version': __VERSION__,
      },
    },
    serviceConfig: config.registerDevice,
  });

  return response.data.initSession;
};

export const mgwRequestExperience = async (config: MLBExperienceConfig): Promise<Experience> => {
  const { accessToken } = getOktaAuthStorage();
  const clientType = await getClientType();
  const { experience } = await requestExperience({
    clientType,
    requestData: {
      headers: {
        ...(accessToken && {
          Authorization: `Bearer ${accessToken}`,
        }),
      },
    },
    serviceConfig: config.experience,
  });

  return experience;
};

export const mgwRequestMediaInfo = async (
  mediaId: string,
  config: MLBExperienceConfig,
): Promise<MediaInfo> => {
  const { accessToken } = getOktaAuthStorage();
  const clientType = await getClientType();
  const mediaInfo = await requestMediaInfo({
    ids: [mediaId],
    serviceConfig: config.mediaInfo,
    ...(accessToken && {
      requestData: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-client-name': clientType,
          'x-client-version': __VERSION__,
        },
      },
    }),
  });

  return mediaInfo[0];
};
