import { actionEventIds, AdobeTracker } from 'analytics/adobe';
import FeedLockIcon from 'assets/feedSelect/feedLock.svg?react';
import {
  FeedLink,
  FeedTeamText,
} from 'components/FeedSelect/StreamSelection/StreamSelection.styles';
import { buildGameVideoToObject, shouldShowSecondaryFeedSelect } from 'components/FeedSelect/utils';
import { ROUTES } from 'constants/screens';
import { feedSelectIds } from 'constants/testIds';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { selectBookmarkTime } from 'store/bookmarks';
import { EpgAndStatsGame, EpgAudioFeed, EpgVideoFeed } from 'store/epg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { StartAt } from 'store/player';
import { setSelectedFeed } from 'store/selectedVideo';
import { selectTeamIdToAbbrv } from 'store/teams';
import { monthDayYear, yearMonthDay } from 'utils/date';
import { getIsMediaOff } from 'utils/gameData';
import { useTextToSpeech } from 'hooks';

import { TeamFeedContainer } from './FeedSelectButton.styles';
import { selectTeamNameById } from 'store/branding';
import { HOME_FEED_TYPE } from 'constants/feeds';
import { ttsGridDirections } from 'utils/textToSpeech/utils';

const { FEED_BUTTON, LOCK_ICON } = feedSelectIds;
const { SELECT_FEED } = actionEventIds();
const { PAYWALL } = ROUTES;

interface FeedSelectButtonProps {
  feed: EpgAudioFeed | EpgVideoFeed;
  feedType: 'audio' | 'video';
  game: EpgAndStatsGame;
  index: number;
  numItems: number;
}

export function FeedSelectButton({ feed, feedType, game, index, numItems }: FeedSelectButtonProps) {
  const { date } = useDateFromURL();
  const { callLetters, contentId, entitled, mediaFeedSubType, mediaFeedType, mediaId } = feed;
  const {
    gameData: {
      away: { teamAbbrv: awayTeamAbbrv, teamId: awayTeamId, teamName: awayTeamName },
      gameDate,
      home: { teamAbbrv: homeTeamAbbrv, teamId: homeTeamId, teamName: homeTeamName },
    },
    gamePk,
  } = game;

  const dispatch = useAppDispatch();
  const bookmarkTime = useAppSelector((state) => selectBookmarkTime(state, contentId));
  const teamIdToAbbrv = useAppSelector(selectTeamIdToAbbrv);
  const awayTeam = useAppSelector((state) => selectTeamNameById(state, awayTeamId)) || awayTeamName;
  const homeTeam = useAppSelector((state) => selectTeamNameById(state, homeTeamId)) || homeTeamName;

  const { t } = useTextToSpeech();

  // checks mediaState and will show feed buttons as long as mediaState
  // is NOT `MEDIA_OFF` - this should be removed in later feedSelect work
  const isFeedMediaOff = getIsMediaOff(feed);
  if (isFeedMediaOff) return null;

  const formattedGameDate = monthDayYear(gameDate);
  const streamMatchup = `${awayTeamAbbrv}@${homeTeamAbbrv}_${formattedGameDate}`;
  const team = teamIdToAbbrv[mediaFeedSubType];

  const onFeedSelect = () => {
    if (entitled) {
      dispatch(setSelectedFeed(feed));

      AdobeTracker.trackAction(SELECT_FEED(callLetters), {
        game: { pk: gamePk },
        stream: { matchup: streamMatchup },
      });
    }
  };

  const showSecondaryFeedSelect = shouldShowSecondaryFeedSelect({
    bookmarkTime,
    entitled,
    feed,
  });

  const buildFeedSelectToObject = () => {
    if (showSecondaryFeedSelect) return undefined;

    return entitled
      ? buildGameVideoToObject({ contentId, date, gamePk, mediaId })
      : {
          pathname: PAYWALL,
          state: { date: yearMonthDay(date), gamePk },
        };
  };

  const to = buildFeedSelectToObject();
  const focusOptions = { focusKey: contentId };

  const ariaLabelProps = {
    awayTeam,
    feedName: `${team}. ${callLetters}`,
    feedType: `${feedType} feed`,
    homeTeam,
  };
  const ariaLabel = [
    entitled
      ? t('feedSelect.feed.entitled', ariaLabelProps)
      : t('feedSelect.feed.notEntitled', ariaLabelProps),
  ];
  const ariaNavigationDirections = ttsGridDirections({
    cols: 2,
    index: mediaFeedType === HOME_FEED_TYPE ? index + 1 : index,
    numItems,
  });
  if (ariaNavigationDirections) {
    ariaLabel.push(
      t('feedSelect.feed.navigation', {
        directions: ariaNavigationDirections,
      }),
    );
  }
  ariaLabel.push(t('feedSelect.feed.back'));

  return (
    <FeedLink
      key={callLetters}
      aria-label={ariaLabel.join(' ')}
      data-testid={`${FEED_BUTTON}-${callLetters}`}
      focusOptions={focusOptions}
      navigateOptions={{
        state: {
          date: yearMonthDay(date),
          feed,
          gamePk,
          startAt: StartAt.BEGINNING,
        },
      }}
      to={to}
      variant="button"
      onPress={onFeedSelect}>
      <TeamFeedContainer $entitled={entitled}>
        <FeedTeamText>{team}</FeedTeamText>: {callLetters}
      </TeamFeedContainer>
      {!entitled && <FeedLockIcon data-testid={LOCK_ICON} />}
    </FeedLink>
  );
}
