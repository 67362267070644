import { BaseErrorModalProps, ErrorModal } from 'components/modals/ErrorModal';
import { networkConnectionModalIds } from 'constants/testIds';
import { useReloadApp, useTextToSpeech } from 'hooks';
import { usePrevious } from 'hooks/usePrevious';
import { useEffect } from 'react';
import { selectStrings } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { selectIsConnected } from 'store/networkConnection';

const { MODAL } = networkConnectionModalIds;

export function NetworkConnectionModal({ isOpen }: BaseErrorModalProps) {
  const reloadApp = useReloadApp();
  const wasOpen = usePrevious(isOpen);
  const isConnected = useAppSelector(selectIsConnected);
  const {
    error: {
      networkError: { primaryText, titleText },
    },
  } = useAppSelector(selectStrings);

  const { speak, t } = useTextToSpeech();

  useEffect(() => {
    // When the network connection resumes while this modal is up
    if (wasOpen && isConnected) {
      reloadApp();
    }
  }, [isConnected, reloadApp, wasOpen]);

  useEffect(() => {
    if (isOpen) {
      speak(t('error.network', { errorMessage: primaryText, errorTitle: titleText }));
    }
  }, [isOpen, primaryText, speak, t, titleText]);

  return <ErrorModal isOpen={isOpen} primaryText={primaryText} testId={MODAL} title={titleText} />;
}
