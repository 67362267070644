import { DeviceSession, Experience } from '@mlbtv-clients/services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AccountEntitlement,
  authorizeSession,
  getAccountEntitlements,
  getSessionInfo,
} from 'experience/DSS';
import { mgwRegisterDevice, mgwRequestExperience } from 'experience/MLB/mediaGateway';
import { fetchEntitlementToken } from 'services/entitlement';
import { AppDispatch, GetState, RootState } from 'store';
import { selectDevExperience } from 'store/app';
import { selectFeatureFlags } from 'store/config';
import { selectAccessToken } from 'store/mlbAuth';
import { isNonProdBuild } from 'utils/env';
import { getDeviceId } from 'utils/platform';
import { setMLBExperienceStorage } from 'utils/storage';

import { selectConfig, setDevice, setExperience } from './experienceSlice';

const { LEGACY, MLB } = Experience;

export const getExperienceFlow = () => async (dispatch: AppDispatch, getState: GetState) => {
  const devExperience = selectDevExperience(getState());
  const { hasMLB, hasTrafficSplitting } = selectFeatureFlags(getState()).experience;
  const config = selectConfig(getState());

  const fallbackExperience = hasMLB ? MLB : LEGACY;

  try {
    let experience;
    if (isNonProdBuild() && devExperience) {
      experience = devExperience;
    } else {
      experience = hasTrafficSplitting ? await mgwRequestExperience(config) : fallbackExperience;
    }

    await dispatch(setExperience(experience));

    return experience;
  } catch (e) {
    console.error('Get Experience Failure: ', e);
    return fallbackExperience;
  }
};

export const authorizeDSSSessionFlow = createAsyncThunk<
  { deviceId: string; entitlements: AccountEntitlement[] },
  undefined,
  { state: RootState }
>('experience/authorizeDSSSession', async (_, { getState }) => {
  const state = getState();
  const {
    device: { id },
  } = await getSessionInfo();
  const accessToken = selectAccessToken(state);

  const { data } = await fetchEntitlementToken(accessToken, id);
  await authorizeSession(data);

  const entitlements = await getAccountEntitlements();

  return { deviceId: id, entitlements };
});

export const registerMLBSessionFlow = createAsyncThunk<
  DeviceSession,
  undefined,
  { state: RootState }
>('experience/registerMLBSession', async (_, { dispatch, getState }) => {
  const experience = getState().experience;
  const { config, device } = experience;
  const response = await mgwRegisterDevice(config, device);

  // Store session information
  const { deviceId } = response;
  if (device) {
    const knownDeviceId = await getDeviceId(deviceId);
    dispatch(setDevice({ ...device, knownDeviceId }));
    setMLBExperienceStorage({ deviceId });
  }

  return response;
});
