import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { feedSelectIds } from 'constants/testIds';
import { selectStrings } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { useTextToSpeech } from 'hooks';

import {
  NoFeedsContainer,
  NoFeedsText,
  NoFeedsTextContainer,
  NoFeedsTitle,
  SignalTower,
} from './NoFeeds.styles';

const { NO_FEEDS, SIGNAL_TOWER } = feedSelectIds;

interface NoFeedsProps {
  awayTeam: string;
  homeTeam: string;
}

export function NoFeeds({ awayTeam, homeTeam }: NoFeedsProps) {
  const {
    games: {
      noFeeds: { primaryText, secondaryText, titleText },
    },
  } = useAppSelector(selectStrings);
  const { ref } = useFocusable();
  const { t } = useTextToSpeech();

  return (
    <NoFeedsContainer
      aria-label={t('feedSelect.early', { awayTeam, homeTeam })}
      data-testid={NO_FEEDS}
      ref={ref}
      tabIndex={0}>
      <SignalTower data-testid={SIGNAL_TOWER} />
      <NoFeedsTitle>{titleText}</NoFeedsTitle>
      <NoFeedsTextContainer>
        <NoFeedsText>{primaryText}</NoFeedsText>
        <NoFeedsText>{secondaryText}</NoFeedsText>
      </NoFeedsTextContainer>
    </NoFeedsContainer>
  );
}
