import { BOTTOM_OF_THE, PREVIEW, TOP_OF_THE } from 'constants/gameState';
import { EpgAndStatsGame, EpgPitcherApi } from 'store/epg';
import { isGameCancelled, isGameForfeited, isGamePostponed } from 'utils/gameData';

import { APPLE_TV, BLACKOUT, FREE_GAME_OF_THE_DAY, PEACOCK, YOUTUBE } from './constants';
import { getGameStatus, getPreviewText } from '../GameStatus/utils';
import { StatsApiConfig } from 'store/config';
import { numberToOrdinalWord } from 'utils/textToSpeech/utils';
import { FALLBACK_ERA, PREVIEW_PITCHER_FALLBACK_TEXT } from 'constants/gameState';

export interface AdobeGameClickActionParam {
  blackedOutVideo: boolean;
  isAppleTv: boolean;
  isFreeGameOfTheDay: boolean;
  isPeacock: boolean;
  isYoutube: boolean;
}

export const getAdobeClickActionOption = ({
  blackedOutVideo,
  isAppleTv,
  isFreeGameOfTheDay,
  isPeacock,
  isYoutube,
}: AdobeGameClickActionParam) => {
  if (blackedOutVideo) {
    if (isAppleTv) return APPLE_TV;
    if (isPeacock) return PEACOCK;
    if (isYoutube) return YOUTUBE;

    return BLACKOUT;
  }

  if (isFreeGameOfTheDay) return FREE_GAME_OF_THE_DAY;

  return null;
};

export const shouldHideGameScore = ({
  abstractGameState,
  detailedState,
  hideSpoilers,
}: {
  abstractGameState: string;
  detailedState: string;
  hideSpoilers: boolean;
}) => {
  const showForCancelled = isGameCancelled(detailedState);
  const showForForfeit = isGameForfeited(detailedState);
  const showForPostponed = isGamePostponed(detailedState);
  const showForPreview = abstractGameState === PREVIEW;

  return showForCancelled || showForForfeit || showForPostponed || showForPreview || hideSpoilers;
};

export const shouldShowMessagePills = (
  focused: boolean,
  savingPitcher: EpgPitcherApi | null,
): boolean => !focused || (focused && !savingPitcher);

interface FormatGameTileAriaPropsArgs {
  awayTeamName: string;
  game: EpgAndStatsGame;
  hideSpoilers: boolean;
  homeTeamName: string;
  index: number;
  numItems: number;
  statusCodes: StatsApiConfig['statusCodes'];
}

export const formatGameTileAriaProps = ({
  awayTeamName,
  game,
  hideSpoilers,
  homeTeamName,
  index,
  numItems,
  statusCodes,
}: FormatGameTileAriaPropsArgs) => {
  const {
    gameData: {
      abstractGameState,
      away,
      away: {
        probablePitcherEra: awayProbablePitcherEra,
        probablePitcherLastName: awayProbablePitcherLastName,
        probablePitcherLosses: awayProbablePitcherLosses,
        probablePitcherWins: awayProbablePitcherWins,
      },
      currentInning,
      currentPitcherEra,
      currentPitcherInningsPitched,
      currentPitcherLastName,
      detailedState,
      home: {
        probablePitcherEra: homeProbablePitcherEra,
        probablePitcherLastName: homeProbablePitcherLastName,
        probablePitcherLosses: homeProbablePitcherLosses,
        probablePitcherWins: homeProbablePitcherWins,
      },
      home,
      isTopInning,
      losingPitcher,
      savingPitcher,
      winningPitcher,
    },
    gameData,
  } = game;

  const gameTime = getPreviewText(gameData);
  const gameStatus = getGameStatus({ game, hideSpoilers, statusCodes });
  const inningOrdinal = numberToOrdinalWord(currentInning);

  const inning = isTopInning
    ? `${TOP_OF_THE} ${inningOrdinal}`
    : `${BOTTOM_OF_THE} ${inningOrdinal}`;

  return {
    abstractGameState,
    awayProbablePitcherEra: awayProbablePitcherEra || FALLBACK_ERA,
    awayProbablePitcherLastName: awayProbablePitcherLastName || PREVIEW_PITCHER_FALLBACK_TEXT,
    awayProbablePitcherLosses,
    awayProbablePitcherWins,
    awayTeamLosses: away.losses,
    awayTeamName,
    awayTeamRuns: away.runs,
    awayTeamWins: away.wins,
    count: numItems,
    currentPitcherEra,
    currentPitcherInningsPitched,
    currentPitcherLastName,
    gameStatus,
    gameTime,
    homeProbablePitcherEra: homeProbablePitcherEra || FALLBACK_ERA,
    homeProbablePitcherLastName: homeProbablePitcherLastName || PREVIEW_PITCHER_FALLBACK_TEXT,
    homeProbablePitcherLosses,
    homeProbablePitcherWins,
    homeTeamLosses: home.losses,
    homeTeamName,
    homeTeamRuns: home.runs,
    homeTeamWins: home.wins,
    index: index + 1,
    inning: currentInning === 0 ? detailedState : inning,
    isBlackedOut: game.blackedOutVideo,
    losingPitcherEra: losingPitcher?.era ?? '0.00',
    losingPitcherInnings: losingPitcher?.inningsPitched ?? '0',
    losingPitcherName: losingPitcher?.lastName ?? '',
    savingPitcherEra: savingPitcher?.era ?? '0.00',
    savingPitcherInnings: savingPitcher?.inningsPitched ?? '0',
    savingPitcherName: savingPitcher?.lastName ?? '',
    winningPitcherEra: winningPitcher?.era ?? '0.00',
    winningPitcherInnings: winningPitcher?.inningsPitched ?? '0',
    winningPitcherName: winningPitcher?.lastName ?? '',
  };
};
