import { selectStrings } from 'store/config';
import { EpgVideoFeed } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { getMvpdMessage } from 'utils/gameData';

import { MvpdText } from './MvpdMessage.styles';
import { useTextToSpeech } from 'hooks';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

interface MvpdMessageProps {
  awayTeam: string;
  homeTeam: string;
  videoFeeds: EpgVideoFeed[];
}

export function MvpdMessage({ awayTeam, homeTeam, videoFeeds }: MvpdMessageProps) {
  const strings = useAppSelector(selectStrings);
  const { t } = useTextToSpeech();
  const { ref } = useFocusable();

  const mvpdMessage = getMvpdMessage(videoFeeds, strings);

  return (
    <MvpdText
      aria-label={t('feedSelect.mvpd', { awayTeam, homeTeam, mvpdMessage })}
      ref={ref}
      tabIndex={0}>
      {mvpdMessage}
    </MvpdText>
  );
}
