import { GameDetails } from 'components/Matchup/GameDetails';
import { TeamDetails } from 'components/Matchup/TeamDetails';
import { matchupIds, messagingIds } from 'constants/testIds';
import { selectGameTileTeamColors } from 'store/branding';
import { selectStatsApi } from 'store/config';
import { EpgAndStatsGame } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { selectHideSpoilers } from 'store/profile';
import { matchupColors } from 'styles/colors';
import { getGameStatusText } from 'utils/gameData';

import {
  GameStatus,
  MatchupAwayTeam,
  MatchupContainer,
  MatchupContent,
  MatchupDivider,
  MatchupHomeTeam,
  MatchupOverlay,
} from './Matchup.styles';

import { LockIcon } from './LockIcon/LockIcon.styles';

const { GAME_STATUS, MATCHUP, MATCHUP_DIVIDER, MATCHUP_GAME_DETAILS, MATCHUP_OVERLAY } = matchupIds;
const { LOCK_ICON } = messagingIds;

const { defaultBackgroundColor } = matchupColors;

interface MatchupProps {
  focused: boolean;
  game: EpgAndStatsGame;
  variant?: 'base' | 'rich';
}

export function Matchup({ focused, game, variant = 'base' }: MatchupProps) {
  const {
    blackedOutVideo,
    entitledVideo,
    gameData,
    gameData: { away, home },
  } = game;
  const teamColors = useAppSelector(selectGameTileTeamColors);
  const hideSpoilers = useAppSelector(selectHideSpoilers);
  const { statusCodes } = useAppSelector(selectStatsApi);

  const awayTeamColor = teamColors[away.teamId] ?? defaultBackgroundColor;
  const homeTeamColor = teamColors[home.teamId] ?? defaultBackgroundColor;
  const showDivider = awayTeamColor === homeTeamColor;
  const gameStatus = getGameStatusText({ gameData, statusCodes });
  const isRich = variant === 'rich';
  const showGameStatus = isRich && gameStatus && !hideSpoilers;
  const shouldShowLockAndOverlay = isRich && (!entitledVideo || blackedOutVideo);

  return (
    <MatchupContainer $isRich={isRich} data-testid={MATCHUP}>
      {shouldShowLockAndOverlay && (
        <>
          <LockIcon data-testid={LOCK_ICON} $focused={focused} />
          <MatchupOverlay data-testid={MATCHUP_OVERLAY} />
        </>
      )}
      <MatchupAwayTeam $backgroundColor={awayTeamColor} $isRich={isRich} />
      {showDivider && <MatchupDivider $isRich={isRich} data-testid={MATCHUP_DIVIDER} />}
      <MatchupHomeTeam $backgroundColor={homeTeamColor} />
      <MatchupContent $focused={focused}>
        {showGameStatus && <GameStatus data-testid={GAME_STATUS}>{gameStatus}</GameStatus>}
        <TeamDetails focused={focused} isRich={isRich} team={away} />
        {isRich && <GameDetails data-testid={MATCHUP_GAME_DETAILS} gameData={gameData} />}
        <TeamDetails focused={focused} isRich={isRich} team={home} />
      </MatchupContent>
    </MatchupContainer>
  );
}
