import LeftChevron from 'assets/player/left-chevron.svg?react';
import { Button } from 'components/common';
import styled from 'styled-components';
import { linescoreColors } from 'styles/colors';

const { inningFocusedColor } = linescoreColors;

export const LinescoreContainer = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
`;

export const LinescoreContent = styled.div``;

export const LinescoreLogos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const InningsContainer = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

export const InningsHeaderContainer = styled(InningsContainer)`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const InningBox = styled(Button)`
  border: none;
  border-radius: 0;
  font-size: ${({ theme }) => theme.fontSize['5xl']};
  font-weight: ${({ theme }) => theme.fontWeight[900]};
  padding: ${({ theme }) => theme.spacing[4]};
  text-align: center;
  width: 6.3rem;

  &.focused {
    background-color: ${inningFocusedColor};
  }
`;

export const InningHeader = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  padding: ${({ theme }) => theme.spacing[3]};
  text-align: center;
  width: 6.3rem;
`;

interface LinescoreArrowProps {
  $rotate?: boolean;
}

export const LinescoreArrow = styled(LeftChevron)<LinescoreArrowProps>`
  align-self: center;
  left: ${({ $rotate }) => ($rotate ? '38.25%' : '4.75%')};
  margin-top: ${({ theme }) => theme.spacing[9]};
  min-width: ${({ theme }) => theme.spacing[5]};
  position: absolute;
  transform: ${({ $rotate }) => $rotate && 'rotate(180deg)'};
`;

export const SpinnerContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[28]};
  width: 50%;
`;
