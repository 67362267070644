enum NavigationButtonBehavior {
  back = 'back',
  down = 'down',
  enter = 'enter',
  left = 'left',
  right = 'right',
  up = 'up',
}

enum MediaButtonBehavior {
  fastForward = 'fastForward',
  pause = 'pause',
  play = 'play',
  playPause = 'playPause',
  rewind = 'rewind',
}

enum CustomButtonBehavior {
  exit = 'exit',
  focusNavBar = 'focusNavBar',
  toggleVideoContentVisibility = 'toggleVideoContentVisibility',
}

export const Buttons = {
  ...NavigationButtonBehavior,
  ...MediaButtonBehavior,
  ...CustomButtonBehavior,
};

export type ButtonBehavior =
  | keyof typeof NavigationButtonBehavior
  | keyof typeof MediaButtonBehavior
  | keyof typeof CustomButtonBehavior;

export type NavigationKeyMappings = Record<ButtonBehavior, number[]>;
