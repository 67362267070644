import { NO_BROADCAST_AVAILABLE } from 'constants/feeds';
import { useTextToSpeech } from 'hooks';

import { NoBroadcastAvailableContainer } from './NoBroadcastAvailable.styles';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';

interface NoBroadcastAvailableProps {
  awayTeam: string;
  homeTeam: string;
}

export function NoBroadcastAvailable({ awayTeam, homeTeam }: NoBroadcastAvailableProps) {
  const { t } = useTextToSpeech();
  const { ref } = useFocusable();

  return (
    <NoBroadcastAvailableContainer
      aria-label={t('feedSelect.noBroadcastAvailable', { awayTeam, homeTeam })}
      ref={ref}
      tabIndex={0}>
      {NO_BROADCAST_AVAILABLE}
    </NoBroadcastAvailableContainer>
  );
}
