import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { isGameBlackout } from 'components/FeedSelect/BlackoutMessage/utils';
import {
  AWAY_FEED_TYPE,
  FULL_GAME_ACCESS,
  GET_MLBTV,
  HOME_FEED_TYPE,
  MEDIA_OFF,
  NATIONAL_FEED_TYPE,
  UPGRADE_MLBTV,
  VIDEO_TITLE,
  WATCH_FREE,
} from 'constants/feeds';
import { BlackoutCodes } from 'constants/feedSelectModal';
import { ROUTES } from 'constants/screens';
import { feedSelectIds } from 'constants/testIds';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { usePrevious } from 'hooks/usePrevious';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectUserCountryCode } from 'store/app';
import { EpgAndStatsGame, EpgAudioFeed, EpgVideoFeed } from 'store/epg';
import { useAppSelector } from 'store/hooks';
import { selectIsAuthenticated } from 'store/mlbAuth';
import { useCondensedAndRecapQuery } from 'store/stats/statsApiSlice';
import { yearMonthDay } from 'utils/date';
import { isGameFinal, shouldShowMvpdMessage } from 'utils/gameData';
import { selectTeamNameById } from 'store/branding';
import { useTextToSpeech } from 'hooks';

import { CondensedAndRecap } from '../CondensedAndRecap';
import { FeedSelectButton } from '../FeedSelectButton';
import { NoBroadcastAvailable } from '../NoBroadcastAvailable/NoBroadcastAvailable';
import {
  FeedsContainer,
  FeedSectionTitle,
  FeedSectionTitleContainer,
  GetOrUpgradeMlbTvButton,
  StreamButtonGrid,
  StreamOptionsContainer,
  StreamsContainer,
  StreamSelectionSpinner,
  VideoSectionIcon,
} from './StreamSelection.styles';

const {
  CONDENSED_BUTTON,
  GET_MLBTV_BUTTON,
  RECAP_BUTTON,
  STREAM_SELECTION,
  UPGRADE_MLBTV_BUTTON,
  VIDEO_ICON,
} = feedSelectIds;
const { NATIONAL_BLACKOUT_CODE, RSN_BLACKOUT_CODE } = BlackoutCodes;
const { PAYWALL } = ROUTES;

interface GetFeedButtonsArgs {
  feedType: string;
  feeds: EpgVideoFeed[];
}

interface StreamSelectionProps {
  game: EpgAndStatsGame;
}

export function StreamSelection({ game }: StreamSelectionProps) {
  const userCountryCode = useAppSelector(selectUserCountryCode);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { focusKey, focusSelf, ref } = useFocusable();
  const { date } = useDateFromURL();
  const { t } = useTextToSpeech();

  const {
    condensedGame,
    gameData,
    gameData: {
      away: { teamId: awayTeamId, teamName: awayTeamName },
      home: { teamId: homeTeamId, teamName: homeTeamName },
    },
    gamePk,
    gameRecap,
    videoFeeds,
    videoStatusCodes,
  } = game;

  const awayTeam = useAppSelector((state) => selectTeamNameById(state, awayTeamId)) || awayTeamName;
  const homeTeam = useAppSelector((state) => selectTeamNameById(state, homeTeamId)) || homeTeamName;

  const isNationalBlackout = isGameBlackout(videoStatusCodes, NATIONAL_BLACKOUT_CODE);
  const isRsnBlackout = isGameBlackout(videoStatusCodes, RSN_BLACKOUT_CODE);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const getFeedButtons = ({ feedType, feeds }: GetFeedButtonsArgs) => {
    const categorizedFeeds = feeds.filter(
      (feed: EpgAudioFeed | EpgVideoFeed) =>
        feed.mediaFeedType === feedType && feed.mediaState !== MEDIA_OFF,
    );

    return categorizedFeeds.map((feed: EpgAudioFeed | EpgVideoFeed, index: number) => (
      <FeedSelectButton
        feedType="video"
        key={feed.contentId}
        feed={feed}
        game={game}
        index={index}
        numItems={videoFeeds.length}
      />
    ));
  };

  const isUnentitledToAllFeeds = videoFeeds.every((feed) => !feed.entitled);

  const awayFeedButtons = getFeedButtons({
    feedType: AWAY_FEED_TYPE,
    feeds: videoFeeds,
  });

  const hasAwayFeeds = awayFeedButtons?.length > 0;
  const homeFeedButtons = getFeedButtons({
    feedType: HOME_FEED_TYPE,
    feeds: videoFeeds,
  });
  const hasHomeFeeds = homeFeedButtons?.length > 0;
  const nationalFeedButtons = getFeedButtons({
    feedType: NATIONAL_FEED_TYPE,
    feeds: videoFeeds,
  });
  const hasNationalFeeds = nationalFeedButtons?.length > 0;
  const isBlackout = isNationalBlackout || isRsnBlackout;
  const isMvpd = shouldShowMvpdMessage(userCountryCode, videoFeeds);
  const hasFeeds = hasHomeFeeds || hasAwayFeeds || hasNationalFeeds;
  const showVideoFeeds = hasFeeds && !isBlackout && !isUnentitledToAllFeeds && isAuthenticated;
  const showFullGameAccessText =
    (isUnentitledToAllFeeds && hasFeeds && !isBlackout) || (!isAuthenticated && hasFeeds);
  const showUpgradeMlbtvButton =
    isAuthenticated && isUnentitledToAllFeeds && hasFeeds && !isBlackout;
  const showGetMlbTvButton = !isAuthenticated && hasFeeds;
  const showNoBroadcastAvailable = !hasFeeds && !isBlackout && !isMvpd;

  const isFinal = isGameFinal(gameData);

  const { data, isLoading } = useCondensedAndRecapQuery(gamePk, {
    skip: !isFinal || (isFinal && !!(condensedGame && gameRecap)),
  });

  const condensedSlug = condensedGame?.slug ?? data?.condensed ?? '';
  const recapSlug = gameRecap?.slug ?? data?.recap ?? '';
  const showWatchFreeText = (condensedSlug || recapSlug) && isUnentitledToAllFeeds;

  const prevIsLoading = usePrevious(isLoading);
  useEffect(() => {
    // Handles the case where condensed and recap was fetched from StatsAPI so that focus doesn't go to the feed button
    if (prevIsLoading && !isLoading && recapSlug) {
      setFocus(RECAP_BUTTON);
    } else if (prevIsLoading && !isLoading && condensedSlug) {
      setFocus(CONDENSED_BUTTON);
    } else if (showUpgradeMlbtvButton) {
      setFocus(UPGRADE_MLBTV_BUTTON);
    } else if (showGetMlbTvButton) {
      setFocus(GET_MLBTV_BUTTON);
    } else {
      focusSelf();
    }
  }, [
    condensedSlug,
    focusSelf,
    isLoading,
    isUnentitledToAllFeeds,
    prevIsLoading,
    recapSlug,
    showGetMlbTvButton,
    showUpgradeMlbtvButton,
  ]);

  // allowing nullish coalescing in showStreamSelection causes the expression to evaluate incorrectly
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const showStreamSelection =
    showVideoFeeds ||
    showUpgradeMlbtvButton ||
    showGetMlbTvButton ||
    showNoBroadcastAvailable ||
    !!condensedSlug ||
    !!recapSlug;
  if (!showStreamSelection) return null;

  if (isLoading) {
    return <StreamSelectionSpinner />;
  }

  const onGetOrUpgradeMlbTvPress = () => {
    const to = {
      pathname: PAYWALL,
    };

    navigate(to, {
      state: {
        date: yearMonthDay(date),
        from: pathname,
        gamePk,
      },
    });
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <FeedsContainer ref={ref} data-testid={STREAM_SELECTION}>
        {showWatchFreeText && (
          <FeedSectionTitleContainer>
            <FeedSectionTitle>{WATCH_FREE}</FeedSectionTitle>
          </FeedSectionTitleContainer>
        )}
        {(condensedSlug || recapSlug) && (
          <CondensedAndRecap condensedSlug={condensedSlug} gamePk={gamePk} recapSlug={recapSlug} />
        )}
        {showVideoFeeds && (
          <FeedSectionTitleContainer>
            <VideoSectionIcon data-testid={VIDEO_ICON} />
            <FeedSectionTitle>{VIDEO_TITLE}</FeedSectionTitle>
          </FeedSectionTitleContainer>
        )}
        {showFullGameAccessText && (
          <FeedSectionTitleContainer>
            <FeedSectionTitle>{FULL_GAME_ACCESS}</FeedSectionTitle>
          </FeedSectionTitleContainer>
        )}
        <StreamsContainer>
          {showNoBroadcastAvailable && (
            <NoBroadcastAvailable awayTeam={awayTeam} homeTeam={homeTeam} />
          )}
          {showUpgradeMlbtvButton && (
            <GetOrUpgradeMlbTvButton
              aria-label={t('feedSelect.upgradeMlbtv', { awayTeam, homeTeam })}
              data-testid={UPGRADE_MLBTV_BUTTON}
              focusOptions={{ focusKey: UPGRADE_MLBTV_BUTTON }}
              scale={false}
              onPress={onGetOrUpgradeMlbTvPress}>
              {UPGRADE_MLBTV}
            </GetOrUpgradeMlbTvButton>
          )}
          {showGetMlbTvButton && (
            <GetOrUpgradeMlbTvButton
              aria-label={t('feedSelect.getMlbtv', { awayTeam, homeTeam })}
              data-testid={GET_MLBTV_BUTTON}
              focusOptions={{ focusKey: GET_MLBTV_BUTTON }}
              scale={false}
              onPress={onGetOrUpgradeMlbTvPress}>
              {GET_MLBTV}
            </GetOrUpgradeMlbTvButton>
          )}
          {showVideoFeeds && (
            <StreamsContainer>
              {hasNationalFeeds && (
                <StreamOptionsContainer>{nationalFeedButtons}</StreamOptionsContainer>
              )}
              <StreamButtonGrid>
                {hasAwayFeeds && <StreamOptionsContainer>{awayFeedButtons}</StreamOptionsContainer>}
                {hasHomeFeeds && <StreamOptionsContainer>{homeFeedButtons}</StreamOptionsContainer>}
              </StreamButtonGrid>
            </StreamsContainer>
          )}
        </StreamsContainer>
      </FeedsContainer>
    </FocusContext.Provider>
  );
}
