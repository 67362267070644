import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// We need the aria-live region to be visible so that the screen reader reads the correct speech
export const AriaLiveRegion = styled.div`
  height: 0;
  left: -9999px;
  position: absolute;
  width: 0;
`;
