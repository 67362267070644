import { actionEventIds, AdobeTracker } from 'analytics/adobe';
import { InningBox } from 'components/video/GameMenu/GameMenuOptions/Details/Linescore/Linescore.styles';
import { shouldShowInningScore } from 'components/video/GameMenu/GameMenuOptions/Details/Linescore/utils';
import { BOTTOM_INNING, TOP_INNING } from 'constants/gameState';
import { Directions } from 'constants/spatialNavigation';
import { usePlayerContext } from 'context/PlayerContext';
import { useTextToSpeech } from 'hooks';
import { useAdobeGameMenuParams } from 'hooks/player';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectInningOffset,
  setCurrentTime,
  setGameMenuOption,
  setInningOffset,
  setIsVideoContentVisible,
  setPressedLinescoreInning,
} from 'store/player';
import { selectHideSpoilers } from 'store/profile';
import {
  selectCurrentInning,
  selectGameLiveMilestone,
  selectInningStartTime,
  selectIsLive,
  selectIsStreamPastGameLiveMilestone,
  selectLatestInningMilestone,
} from 'store/selectedVideo';
import { numberToOrdinalWord } from 'utils/textToSpeech/utils';

const { VIDEO_PLAYER_LINESCORE_INNING_SELECTED } = actionEventIds();

interface LinescoreInningProps {
  firstFocusableInning: number;
  inning: number;
  isAway: boolean;
  lastFocusableInning: number;
  score?: number;
  shouldShowLeftInningArrow: boolean;
  shouldShowRightInningArrow: boolean;
  teamName: string;
}

export function LinescoreInning({
  firstFocusableInning,
  inning,
  isAway,
  lastFocusableInning,
  score,
  shouldShowLeftInningArrow,
  shouldShowRightInningArrow,
  teamName,
}: LinescoreInningProps) {
  const dispatch = useAppDispatch();

  const { currentInning = 1, isTop } = useAppSelector(selectCurrentInning);
  const isLive = useAppSelector(selectIsLive);
  const hideSpoilers = useAppSelector(selectHideSpoilers);
  const inningOffset = useAppSelector(selectInningOffset);
  const gameLiveMilestone = useAppSelector(selectGameLiveMilestone);
  const latestInningMilestone = useAppSelector(selectLatestInningMilestone);
  const isPastGameLiveTime = useAppSelector(selectIsStreamPastGameLiveMilestone);
  const inningStartTime = useAppSelector((state) =>
    selectInningStartTime(state, { inning, isTop: isAway }),
  );

  const topOrBot = isAway ? TOP_INNING : BOTTOM_INNING;
  const focusKey = `${topOrBot}-${inning}`;

  const shouldShowScore = useMemo(
    () =>
      shouldShowInningScore({
        currentInning,
        gameLiveMilestone,
        hideSpoilers,
        inning,
        isAway,
        isLive,
        isPastGameLiveTime,
        isTop,
        latestInningMilestone,
      }),
    [
      currentInning,
      gameLiveMilestone,
      hideSpoilers,
      inning,
      isAway,
      latestInningMilestone,
      isLive,
      isPastGameLiveTime,
      isTop,
    ],
  );
  const runs = shouldShowScore ? `${score ?? 0}` : '';
  const { t } = useTextToSpeech();

  const { seekTo } = usePlayerContext();
  const adobeLinescoreInningClickParams = useAdobeGameMenuParams(runs);

  const onArrowPress = useCallback(
    (direction: string) => {
      const prevInning = inning - 1;
      const nextInning = inning + 1;

      // Extra innings handling
      if (
        shouldShowLeftInningArrow &&
        prevInning === firstFocusableInning &&
        direction === Directions.Left
      ) {
        dispatch(setInningOffset(inningOffset - 1));
      }
      if (
        shouldShowRightInningArrow &&
        nextInning === lastFocusableInning &&
        direction === Directions.Right
      ) {
        dispatch(setInningOffset(inningOffset + 1));
      }

      const shouldNotAllowLeftArrowPress =
        inning === firstFocusableInning && direction === Directions.Left;
      const shouldNotAllowRightArrowPress =
        inning === lastFocusableInning && direction === Directions.Right;

      return !shouldNotAllowLeftArrowPress && !shouldNotAllowRightArrowPress;
    },
    [
      dispatch,
      firstFocusableInning,
      inning,
      inningOffset,
      lastFocusableInning,
      shouldShowLeftInningArrow,
      shouldShowRightInningArrow,
    ],
  );

  const onPress = useCallback(() => {
    if (inningStartTime) {
      dispatch(setIsVideoContentVisible(false));
      dispatch(setCurrentTime(inningStartTime));
      dispatch(setPressedLinescoreInning(true));
      dispatch(setGameMenuOption(null));
      seekTo(inningStartTime);

      AdobeTracker.trackAction(VIDEO_PLAYER_LINESCORE_INNING_SELECTED, {
        inningState: focusKey,
        ...adobeLinescoreInningClickParams,
      });
    }
  }, [adobeLinescoreInningClickParams, dispatch, focusKey, inningStartTime, seekTo]);

  return (
    <InningBox
      key={focusKey}
      aria-label={t('gameMenu.details.inningFocus', {
        halfInning:
          topOrBot === TOP_INNING ? t('gameMenu.details.top') : t('gameMenu.details.bottom'),
        inning: numberToOrdinalWord(inning),
        runs,
        teamName,
      })}
      data-testid={focusKey}
      focusOptions={{
        focusKey,
        focusable: shouldShowScore,
        onArrowPress,
      }}
      onPress={onPress}>
      {runs}
    </InningBox>
  );
}
