/* eslint-disable sort-keys */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config } from 'store/config';

import { NETWORK_ERROR_MESSAGE } from './constants';
import { QueryParams, RequestConfig, RequestInfo, Verb } from './types';

let config: Config = {} as Config;

export const getConfig = (): Config => config;

export const init = (appConfig: Config, isInitialConfigs: boolean) => {
  if (!isInitialConfigs) {
    config = appConfig;
  } else if (Object.keys(config).length === 0) {
    // only set the fallback config if the config
    // has not yet been set to prevent fallback configs
    // from overwriting configs from GCP
    config = appConfig;
  }
};

export const replaceTemplatedUrl = (url: string, substitutions?: QueryParams) => {
  if (!substitutions || Object.keys(substitutions).length === 0) return url;

  return Object.keys(substitutions).reduce((acc, sub) => {
    const replaceTemplate = `\${${sub}}`;
    const replaceValue = substitutions[sub];
    return acc.replace(replaceTemplate, replaceValue);
  }, url);
};

export function fetchUrlFromConfig(requestConfig: RequestConfig): string {
  const { endpoint, queryParams, service } = requestConfig;
  const { baseUrl, endpoints } = config.services[service];

  const url = `${baseUrl}${(endpoints as any)?.[endpoint]}`;

  return replaceTemplatedUrl(url, queryParams);
}
export default async <T>(
  verb: Verb,
  requestConfig: RequestConfig,
  data: RequestInfo = {},
): Promise<AxiosResponse<T>> => {
  const url = fetchUrlFromConfig(requestConfig);
  let response;
  try {
    const axiosConfig: AxiosRequestConfig = {
      data: data.body,
      headers: {
        ...data.headers,
      },
      method: verb,
      timeout: data.timeoutMs ?? 0,
      url,
      withCredentials: data.withCredentials ?? false,
    };

    response = await axios(axiosConfig);

    return response;
  } catch (error) {
    if (!error.response) {
      throw new Error(NETWORK_ERROR_MESSAGE);
    }

    throw error;
  }
};
