import { createGlobalStyle } from 'styled-components';
import Fonts from 'styles/fonts';
import CSSReset from 'styles/reset';

export default createGlobalStyle`

  ${CSSReset}

  /* Custom Global Styles */
  * {
    outline: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  #root {
    height: 100%;
  }

  html {
    background: ${({ theme }) => theme.colors.background};
    overflow: hidden;
    font-size: 10px;
    min-height: 108em;
  }

  @media (max-width: 1280px) {
      html {
          font-size: 6.6px;
      }
  }

  body {
    color: ${({ theme }) => theme.colors.bodyText};
    font-family: "MLB Bullpen Neue", sans-serif;
  }

  iframe {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ${Fonts}
`;
