import { Button } from 'components/common';
import { ErrorModal, ErrorModalProps } from 'components/modals/ErrorModal';
import { appIds } from 'constants/testIds';
import { ReportableErrorType, reportErrorToDevice } from 'utils/deviceMetrics';
import { selectStrings } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { useTextToSpeech } from 'hooks';

const { APP_ERROR_BOUNDARY_BUTTON, APP_ERROR_BOUNDARY_MODAL } = appIds;

interface AppErrorBoundaryProps extends Partial<ErrorModalProps> {
  resetErrorBoundary: () => void;
}

export function AppErrorBoundary({ isOpen = true, resetErrorBoundary }: AppErrorBoundaryProps) {
  const {
    error: {
      criticalError: { buttonText, primaryText },
    },
  } = useAppSelector(selectStrings);

  const { t } = useTextToSpeech();

  reportErrorToDevice(ReportableErrorType.OTHER, 'ERROR', primaryText, true);

  return (
    <ErrorModal
      button={
        <Button
          aria-label={t('error.appErrorBoundary', { errorMessage: primaryText })}
          data-testid={APP_ERROR_BOUNDARY_BUTTON}
          onPress={resetErrorBoundary}>
          {buttonText}
        </Button>
      }
      isOpen={isOpen}
      primaryText={primaryText}
      testId={APP_ERROR_BOUNDARY_MODAL}
    />
  );
}
