import { merge } from 'lodash';
import calendarTranslations from './calendar.json';
import carouselTranslations from './carousel.json';
import createAccountTranslations from './createAccount.json';
import errorTranslations from './error.json';
import favoriteTeamTranslations from './favoriteTeam.json';
import feedSelectTranslations from './feedSelect.json';
import followingTeamTranslations from './followingTeam.json';
import forgeTileTranslations from './forgeTile.json';
import gameMenuTranslations from './gameMenu.json';
import gamesTranslations from './games.json';
import gameTileTranslations from './gameTile.json';
import getStartedTranslations from './getStarted.json';
import headerTranslations from './header.json';
import heroTranslations from './hero.json';
import loginTranslations from './login.json';
import navigationTranslations from './navigation.json';
import paywallTranslations from './paywall.json';
import playerTranslations from './player.json';
import purchaseTranslations from './purchase.json';
import quickActionsTranslations from './quickActions.json';
import settingsTranslations from './settings.json';
import welcomeTranslations from './welcome.json';

export const en = merge(
  calendarTranslations,
  carouselTranslations,
  createAccountTranslations,
  errorTranslations,
  favoriteTeamTranslations,
  feedSelectTranslations,
  followingTeamTranslations,
  forgeTileTranslations,
  gameMenuTranslations,
  gamesTranslations,
  gameTileTranslations,
  getStartedTranslations,
  headerTranslations,
  heroTranslations,
  loginTranslations,
  navigationTranslations,
  paywallTranslations,
  playerTranslations,
  purchaseTranslations,
  quickActionsTranslations,
  settingsTranslations,
  welcomeTranslations,
);
