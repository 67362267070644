import { Directions } from 'constants/spatialNavigation';
import { isEmpty, isNil, negate, overSome } from 'lodash';
import { format, getDate, getYear } from 'utils/date';

export const getElementById = (id: string) => document.getElementById(id);

export const isNeitherNilOrEmpty = negate(overSome(isNil, isEmpty));

export const numberToOrdinalWord = (number: number) => {
  const ordinalWords = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
    'twentieth',
    'twenty-first',
    'twenty-second',
    'twenty-third',
    'twenty-fourth',
    'twenty-fifth',
    'twenty-sixth',
    'twenty-seventh',
    'twenty-eighth',
    'twenty-ninth',
    'thirtieth',
    'thirty-first',
  ];
  return ordinalWords[number - 1] || number; // Returns number if the number is out of range
};

export const formatTtsDateString = (dateString: string): string => {
  let formattedTtsDateString = '';

  formattedTtsDateString += format(dateString, 'MMMM') + ',';

  formattedTtsDateString += numberToOrdinalWord(getDate(dateString)) + ',';

  formattedTtsDateString += getYear(dateString);

  return formattedTtsDateString.trim();
};

export const formatForgeVideoTimeTts = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  let formattedForgeVideoTimeString = '';

  if (hours > 0) {
    formattedForgeVideoTimeString += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
  }

  if (minutes > 0) {
    formattedForgeVideoTimeString += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} `;
  }

  if (seconds > 0) {
    formattedForgeVideoTimeString += `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
  }

  return formattedForgeVideoTimeString.trim();
};

export const formatTimeStringTts = (timeString: string): string => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
  }

  if (minutes > 0) {
    formattedTime += `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} `;
  }

  if (seconds > 0) {
    formattedTime += `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
  }

  return formattedTime.trim();
};

// Choose the appropriate TTS text for the GameTile based on the location of the current GameTile in the grid (grid width === cols)
const getPosition = (index: number, cols: number, colStart: number) => ({
  // Row is the index divided by number of columns
  col: (index + colStart) % cols,
  row: Math.floor(index / cols), // Column is the remainder of the index divided by columns
});

interface TtsGridDirectrionsProps {
  colStart?: number; // colStart handles the case where the grid does not start at the first column (e.g. calendar grid)
  cols: number;
  index: number;
  numItems: number;
}

export const ttsGridDirections = ({
  colStart = 0,
  cols,
  index,
  numItems,
}: TtsGridDirectrionsProps) => {
  const { col, row } = getPosition(index, cols, colStart);

  const rows = Math.ceil(numItems / cols);

  const directions = [];

  // Can move up?
  if (row > 0 && index - cols >= 0) {
    directions.push(Directions.Up);
  }

  // Can move down?
  if (row < rows - 1 && index + cols < numItems) {
    directions.push(Directions.Down);
  }

  // Can move left?
  if (col > 0 && index !== 0) {
    directions.push(Directions.Left);
  }

  // Can move right?
  if (col < cols - 1 && index + 1 < numItems) {
    directions.push(Directions.Right);
  }

  const directionsText = `${directions.slice(0, -1).join(', ')}, or ${directions.slice(-1)}`;

  return directions.length > 1 ? directionsText : directions[0];
};
