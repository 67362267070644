import Hls from 'hls.js';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface IPlayerContext {
  convivaAdStreamManager: any | null;
  createPlayer: (hls: Hls) => void;
  destroyConvivaAdStreamManager: () => void;
  destroyPlayer: () => void;
  getCurrentTime: () => number;
  getDuration: () => number;
  handleConvivaAdStreamManager: (streamManager: any) => void;
  handleNativePlayer: (video: HTMLVideoElement) => void;
  hls: Hls | null;
  isHlsCurrent: boolean;
  seekTo: (time: number) => void;
  video: HTMLVideoElement | null;
}

export const PlayerContext = createContext<IPlayerContext>({} as IPlayerContext);

interface PlayerProviderProps {
  children: ReactNode;
}

export function PlayerProvider({ children }: PlayerProviderProps) {
  const hlsRef = useRef<Hls | null>(null);
  const [isHlsCurrent, setIsHlsCurrent] = useState(false);
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const [convivaAdStreamManager, setConvivaAdStreamManager] = useState<any>(null);

  const createPlayer = useCallback((newHls: Hls) => {
    hlsRef.current = newHls;
    setIsHlsCurrent(true);
  }, []);

  const destroyConvivaAdStreamManager = useCallback(() => {
    setConvivaAdStreamManager(null);
  }, []);

  const destroyPlayer = useCallback(async () => {
    if (hlsRef.current?.media) {
      try {
        hlsRef.current.destroy();
        hlsRef.current = null;
        setVideo(null);
        setIsHlsCurrent(false);
      } catch (error) {
        console.error('Could not properly destroy player');
        throw error;
      }
    }
  }, []);

  const handleConvivaAdStreamManager = useCallback((streamManger: any) => {
    setConvivaAdStreamManager(streamManger);
  }, []);

  const handleNativePlayer = useCallback((videoElement: HTMLVideoElement) => {
    setVideo(videoElement);
  }, []);

  const getCurrentTime = useCallback(
    () => (video?.currentTime ? Math.floor(video.currentTime) : 0),
    [video],
  );

  const getDuration = useCallback(() => video?.duration ?? 0, [video]);

  const seekTo = useCallback(
    (time: number) => {
      if (video) {
        video.currentTime = time;
      }
    },
    [video],
  );

  const value = useMemo(
    () => ({
      convivaAdStreamManager,
      createPlayer,
      destroyConvivaAdStreamManager,
      destroyPlayer,
      getCurrentTime,
      getDuration,
      handleConvivaAdStreamManager,
      handleNativePlayer,
      hls: hlsRef.current,
      isHlsCurrent,
      seekTo,
      video,
    }),
    [
      convivaAdStreamManager,
      createPlayer,
      destroyConvivaAdStreamManager,
      handleConvivaAdStreamManager,
      destroyPlayer,
      getCurrentTime,
      getDuration,
      handleNativePlayer,
      isHlsCurrent,
      seekTo,
      video,
    ],
  );
  return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>;
}

export function usePlayerContext() {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error('usePlayerContext must be used within a PlayerProvider');
  }

  return context;
}
