import { Experience } from '@mlbtv-clients/services';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ConfigEnv } from 'store/config';
import { getEnvFromHost } from 'utils/env';
import { getDeviceCountryCode } from 'utils/platform';

export type AppState = {
  configEnv: ConfigEnv;
  deepLink: string | null;
  devExperience: Experience | null;
  height: number;
  isAppInitialized: boolean;
  isBackgrounded: boolean;
  openModal: string | null;
  userBandwidth: number | undefined;
  userCountryCode: string;
};

export const initialState: AppState = {
  configEnv: getEnvFromHost(),
  deepLink: null,
  devExperience: null,
  height: document.documentElement.clientHeight,
  isAppInitialized: false,
  isBackgrounded: false,
  openModal: null,
  userBandwidth: undefined,
  userCountryCode: '',
};

// flows
export const getUserCountryCodeFlow = createAsyncThunk<string, undefined, { state: RootState }>(
  'app/getUserCountryCode',
  getDeviceCountryCode,
);

// Slice
export const appSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(getUserCountryCodeFlow.fulfilled, (state, action) => ({
      ...state,
      userCountryCode: action.payload,
    }));

    builder.addCase(getUserCountryCodeFlow.rejected, (state) => ({
      ...state,
      userCountryCode: '',
    }));
  },
  initialState,
  name: 'app',
  reducers: {
    setConfigEnv(state: AppState, action: PayloadAction<ConfigEnv>) {
      state.configEnv = action.payload;
    },
    setDeepLink(state: AppState, action: PayloadAction<string | null>) {
      state.deepLink = action.payload;
    },
    setDevExperience(state: AppState, action: PayloadAction<Experience | null>) {
      state.devExperience = action.payload;
    },
    setIsAppInitialized(state: AppState, action: PayloadAction<boolean>) {
      state.isAppInitialized = action.payload;
    },
    setIsBackgrounded(state: AppState, action: PayloadAction<boolean>) {
      state.isBackgrounded = action.payload;
    },
    setOpenModal(state: AppState, action: PayloadAction<string | null>) {
      state.openModal = action.payload;
    },
    setUserBandwidth(state: AppState, action: PayloadAction<number>) {
      state.userBandwidth = action.payload;
    },
  },
});

// Actions
export const {
  setConfigEnv,
  setDeepLink,
  setDevExperience,
  setIsAppInitialized,
  setIsBackgrounded,
  setOpenModal,
  setUserBandwidth,
} = appSlice.actions;

// Selectors
export const selectConfigEnv = (state: RootState) => state.app.configEnv;

export const selectDevExperience = (state: RootState) => state.app.devExperience;

export const selectDeepLink = (state: RootState) => state.app.deepLink;

export const selectHeight = (state: RootState) => state.app.height;

export const selectIsAppInitialized = (state: RootState) => state.app.isAppInitialized;

export const selectIsBackgrounded = (state: RootState) => state.app.isBackgrounded;

export const selectIsModalOpen = (state: RootState) => !!state.app.openModal;

export const selectOpenModal = (state: RootState) => state.app.openModal;

export const selectUserBandwidth = (state: RootState) => state.app.userBandwidth;

export const selectUserCountryCode = (state: RootState) => state.app.userCountryCode;

export default appSlice.reducer;
