import { PlaylistTopicValues } from 'services/playlist';

// Maps a header button to button text
export const HEADER_BUTTONS = {
  CLIPS: 'Clips',
  GAMES: 'Games',
  GET_MLBTV: 'Get MLB.TV',
  HOME: 'Home',
  LOG_IN: 'Log In',
  SETTINGS: 'Settings',
  SHOWS: 'Shows',
  UPGRADE_MLBTV: 'Upgrade MLB.TV',
  WELCOME: 'Welcome',
};

// Maps a page name to a route
export const ROUTES = {
  CLIPS: '/clips',
  CREATE_ACCOUNT: '/createAccount',
  CREATE_ACCOUNT_SUCCESS: '/createAccountSuccess',
  EXIT: '/exit',
  FAV_TEAM_PICKER: '/favTeamPicker',
  FOLLOW_TEAM_PICKER: '/followTeamPicker',
  FORGE_VIDEO: '/forgeVideo',
  GAME_VIDEO: '/gameVideo',
  GAMES: '/games',
  GET_STARTED: '/getStarted',
  HOME: '/',
  LOGIN: '/login',
  LOGIN_CALLBACK: '/login/callback',
  PAYWALL: '/paywall',
  PURCHASE: '/purchase',
  SETTINGS: '/settings',
  SHOWS: '/shows',
  WELCOME: '/welcome',
};

// Maps the press of a header button to a route
export const HEADER_BUTTON_ROUTE_MAP = {
  [HEADER_BUTTONS.CLIPS]: ROUTES.HOME,
  [HEADER_BUTTONS.GAMES]: ROUTES.GAMES,
  [HEADER_BUTTONS.GET_MLBTV]: ROUTES.WELCOME,
  [HEADER_BUTTONS.HOME]: ROUTES.HOME,
  [HEADER_BUTTONS.LOG_IN]: ROUTES.LOGIN,
  [HEADER_BUTTONS.SETTINGS]: ROUTES.SETTINGS,
  [HEADER_BUTTONS.SHOWS]: ROUTES.HOME,
  [HEADER_BUTTONS.UPGRADE_MLBTV]: ROUTES.PAYWALL,
  [HEADER_BUTTONS.WELCOME]: ROUTES.WELCOME,
};

// Maps a route to a header button for the purpose of focusing on the correct button when focusing on the header
export const FOCUS_ROUTE_TO_HEADER_BUTTON: { [key: string]: string } = {
  [ROUTES.HOME]: HEADER_BUTTONS.HOME,
  [ROUTES.GAMES]: HEADER_BUTTONS.GAMES,
  [ROUTES.SETTINGS]: HEADER_BUTTONS.SETTINGS,
};

// Maps a route to a PlaylistTopicValue
export const ROUTE_TO_PLAYLIST_TOPIC: {
  [key: string]: PlaylistTopicValues;
} = {
  [ROUTES.HOME]: PlaylistTopicValues.HOME,
  [ROUTES.CLIPS]: PlaylistTopicValues.CLIPS,
  [ROUTES.SHOWS]: PlaylistTopicValues.SHOWS,
};

// Header Button Types
export const ACCOUNT = 'account';
export const CONTENT = 'content';
export type HeaderButtonType = typeof ACCOUNT | typeof CONTENT;
