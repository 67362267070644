import { NavigationKeyMappings } from 'utils/buttons';

enum ControllerButtons {
  /**  Esc */
  CIRCLE = 27,
  /**  Enter */
  CROSS = 13,
  /**  Down */
  DOWN = 40,
  /**  Remote Fast Forward */
  FAST_FORWARD = 134,
  /**  F5 */
  L1 = 116,
  /**  F7 */
  L2 = 118,
  /**  F9 */
  L3 = 120,
  /**  Left */
  LEFT = 37,
  /**  F3 */
  OPTIONS = 114,
  /** Remote Play/Pause */
  PLAY_PAUSE = 217,
  /**  F6 */
  R1 = 117,
  /**  F8 */
  R2 = 119,
  /**  F10 */
  R3 = 121,
  /**  Remote Rewind */
  REWIND = 133,
  /**  Right */
  RIGHT = 39,
  /**  F2 */
  SQUARE = 113,
  /**  F1 */
  TRIANGLE = 112,
  /**  Up */
  UP = 38,
}

const NavigationButtons = {
  back: [ControllerButtons.CIRCLE],
  down: [ControllerButtons.DOWN],
  enter: [ControllerButtons.CROSS],
  left: [ControllerButtons.LEFT],
  right: [ControllerButtons.RIGHT],
  up: [ControllerButtons.UP],
};

const MediaButtons = {
  fastForward: [ControllerButtons.FAST_FORWARD, ControllerButtons.R2],
  pause: [],
  play: [],
  playPause: [ControllerButtons.PLAY_PAUSE],
  rewind: [ControllerButtons.REWIND, ControllerButtons.L2],
};

const CustomButtons = {
  exit: [],
  focusNavBar: [ControllerButtons.TRIANGLE],
  toggleVideoContentVisibility: [
    ControllerButtons.CROSS,
    ControllerButtons.DOWN,
    ControllerButtons.FAST_FORWARD,
    ControllerButtons.L1,
    ControllerButtons.L3,
    ControllerButtons.LEFT,
    ControllerButtons.PLAY_PAUSE,
    ControllerButtons.R1,
    ControllerButtons.R3,
    ControllerButtons.REWIND,
    ControllerButtons.RIGHT,
    ControllerButtons.SQUARE,
    ControllerButtons.TRIANGLE,
    ControllerButtons.UP,
  ],
};

export const NAVIGATION_KEY_MAP: NavigationKeyMappings = {
  ...NavigationButtons,
  ...MediaButtons,
  ...CustomButtons,
};
