import { useEffect } from 'react';
import { setUserBandwidth } from 'store/app';
import { selectIntervals } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsStarted } from 'store/player';

export function BandwidthEstimator() {
  const dispatch = useAppDispatch();
  const { BANDWIDTH_ESTIMATION_INTERVAL } = useAppSelector(selectIntervals);

  const isPlaying = useAppSelector(selectIsStarted);

  useEffect(() => {
    const testIntervalMs = BANDWIDTH_ESTIMATION_INTERVAL ?? 300000;
    const measureBandwidth = async () => {
      if (!isPlaying) {
        const startBandwidthTestTime = performance.now();

        // The bwt file is a generated 200Kb binary file that is used to measure bandwidth
        // This file was generated by running `dd if=/dev/urandom of=bwt bs=1024 count=200`
        const response = await fetch(`/bwt`, { cache: 'no-store' });
        const data = await response.blob();
        const endBandwidthTestTime = performance.now();

        const fileSizeInBytes = data.size;
        const fileSizeInBits = fileSizeInBytes * 8;
        const timeTakenInSeconds = (endBandwidthTestTime - startBandwidthTestTime) / 1000;
        const bandwidthBitsPerSecond = Math.floor(fileSizeInBits / timeTakenInSeconds);

        dispatch(setUserBandwidth(bandwidthBitsPerSecond));
      }
    };

    measureBandwidth();

    const intervalId = setInterval(measureBandwidth, testIntervalMs);

    return () => clearInterval(intervalId);
  }, [dispatch, isPlaying, BANDWIDTH_ESTIMATION_INTERVAL]);

  return null;
}
