import styled from 'styled-components';

interface BroadcastOptionsFeedsProps {
  $numColumns: number;
}

export const BroadcastOptionsContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing[10]};
  width: 100%;
`;

export const BroadcastOptionsOptionsTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

export const BroadcastOptionsFeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15rem;
`;

export const BroadcastOptionsFeeds = styled.div<BroadcastOptionsFeedsProps>`
  display: flex;
  flex-flow: column wrap;
  max-height: 15rem;
`;
