import { createLogger } from 'redux-logger';

// log redux statements in development only.  logging overwhelms slower devices in production.
const enabled = __NODE_ENV__ === 'development';

export const logger = enabled
  ? createLogger({
      collapsed: true,
      diff: true,
    })
  : null;
