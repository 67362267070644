import {
  BOTTOM_INNING,
  CANCELLED_STATUS,
  FINAL,
  FORFEIT_STATUS,
  GAME,
  POSTGAME_SHOW,
  POSTPONED_STATUS,
  PREGAME_SHOW,
  TBD,
  TOP_INNING,
  WARMUP,
  WARMUP_STATUS_CODE,
} from 'constants/gameState';
import { StatsApiConfig } from 'store/config';
import { EpgAndStatsGame, EpgAndStatsGameData } from 'store/epg';
import { hourMinuteAMPM } from 'utils/date';
import {
  isPostGameLiveAndEntitledForTeam,
  isPreGameLiveAndEntitledForTeam,
  getGameStatusFromStatusCode,
} from 'utils/gameData';
import { AWAY_TEAM, HOME_TEAM, LIVE, PREVIEW } from 'constants/gameState';

export const getFinalText = (
  gameData: EpgAndStatsGameData,
  statusCodes: StatsApiConfig['statusCodes'],
  hideSpoilers: boolean,
): string => {
  const { currentInning, statusCode } = gameData;
  const shouldRenderInningNumber = currentInning !== 9 && !hideSpoilers;
  const finalText = shouldRenderInningNumber
    ? `${FINAL.toUpperCase()}/${currentInning}`
    : FINAL.toUpperCase();
  const statusText = getGameStatusFromStatusCode(statusCode, statusCodes);
  const cancelled = statusText === CANCELLED_STATUS;
  const forfeit = statusText === FORFEIT_STATUS;
  const postponed = statusText === POSTPONED_STATUS;

  switch (true) {
    case cancelled:
      return CANCELLED_STATUS;
    case forfeit:
      return FORFEIT_STATUS;
    case postponed:
      return POSTPONED_STATUS;
    default:
      return finalText;
  }
};

export const getLiveText = (gameData: EpgAndStatsGameData, hideSpoilers: boolean): string => {
  const { currentInning, currentInningState, statusCode } = gameData;
  const isWarmup = statusCode === WARMUP_STATUS_CODE;
  const inningState =
    currentInningState === TOP_INNING ? TOP_INNING.toUpperCase() : BOTTOM_INNING.toUpperCase();
  const liveText = `${inningState} ${currentInning}`;

  if (hideSpoilers) return '';

  return isWarmup ? WARMUP.toUpperCase() : liveText;
};

export const getPrePostText = (
  isPostGameAvailable: boolean,
  isPreGameAvailable: boolean,
): string => {
  switch (true) {
    case isPostGameAvailable:
      return POSTGAME_SHOW;
    case isPreGameAvailable:
      return PREGAME_SHOW;
    default:
      return '';
  }
};

export const getPreviewText = (gameData: EpgAndStatsGameData): string => {
  const { doubleHeader, gameDate, gameNumber, startTimeTBD } = gameData;
  const startTime = hourMinuteAMPM(gameDate);

  // in the case that the game is a single ticket DH and
  // the second game is not scheduled, show "Game 2"
  if (startTimeTBD && doubleHeader === 'Y') {
    return `${GAME} ${gameNumber}`;
  }

  if (startTimeTBD) {
    return TBD;
  }

  return startTime;
};

export const shouldShowPrePostContent = (game: EpgAndStatsGame) => {
  const isPostGameLiveAndEntitledForAway = isPostGameLiveAndEntitledForTeam(game, AWAY_TEAM);
  const isPostGameLiveAndEntitledForHome = isPostGameLiveAndEntitledForTeam(game, HOME_TEAM);
  const isPreGameLiveAndEntitledForAway = isPreGameLiveAndEntitledForTeam(game, AWAY_TEAM);
  const isPreGameLiveAndEntitledForHome = isPreGameLiveAndEntitledForTeam(game, HOME_TEAM);

  const isPostGameAvailable = isPostGameLiveAndEntitledForAway || isPostGameLiveAndEntitledForHome;
  const isPreGameAvailable = isPreGameLiveAndEntitledForAway || isPreGameLiveAndEntitledForHome;

  return {
    isPostGameAvailable,
    isPreGameAvailable,
    showPrePostContent: isPostGameAvailable || isPreGameAvailable,
  };
};

interface GetGameStatusArgs {
  game: EpgAndStatsGame;
  hideSpoilers: boolean;
  statusCodes: StatsApiConfig['statusCodes'];
}

export const getGameStatus = ({ game, hideSpoilers, statusCodes }: GetGameStatusArgs) => {
  const {
    gameData,
    gameData: { abstractGameState },
  } = game;

  const { isPostGameAvailable, isPreGameAvailable, showPrePostContent } =
    shouldShowPrePostContent(game);

  switch (true) {
    case showPrePostContent:
      return getPrePostText(isPostGameAvailable, isPreGameAvailable);
    case abstractGameState === FINAL:
      return getFinalText(gameData, statusCodes, hideSpoilers);
    case abstractGameState === LIVE:
      return getLiveText(gameData, hideSpoilers);
    case abstractGameState === PREVIEW:
      return getPreviewText(gameData);
    default:
      return '';
  }
};
